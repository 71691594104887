import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/SEO'
import Layout from 'components/Layout'

import Button from 'components/Button'

const NotFoundPage = () => (
  <Layout location="en" pathname="home">
    <SEO title="404: Not found | Hagerty Quebec" />
    <div className="center center--404">
      <h1 className="title-h-3 t-l b-s">Sorry</h1>
      <p className="txt-2 t-m b-l">We couldn’t find that page</p>
      <Button variant="link" component={Link} to="/">
        Go to Home page
      </Button>
    </div>
  </Layout>
)

export default NotFoundPage
